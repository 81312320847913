<template>
  <div class="score-tracking-container">
    <div v-if="!isVisiblePreOrderHistory">
      <VueTabs @selectedTab="setActiveTab">
        <VueTab
          v-for="(item, index) in tabItems"
          :key="index"
          :name="item"
          :id="index"
          :selected="activeTabIndex === index"
        >
          <div class="score-tracking-content">
            <BrandBarChart
              @selectedBarItem="selectedBarItem"
              :chartData="barChartData"
              :chartOptions="barChartOptions"
              :hasBackgroundBarPlugin="true"
              :activeTabIndex="activeTabIndex"
              :activeYear="activeYear.date"
              :hasSelectedBar="true"
            />
            <div class="chart-divider" />
            <div class="chart-date-filter">
              <div
                class="chart-date-item"
                v-for="(item, index) in dateFilterItems"
                @click="toggleDateFilter(item)"
                :key="index"
              >
                <img
                  :src="
                    item.isActive
                      ? require('@/assets/icons/green-check.svg')
                      : require('@/assets/icons/empty-ellipse.svg')
                  "
                  alt="icon"
                />
                <div
                  :style="{ color: item.isPrev ? '#ffc107' : '#F16F1B' }"
                  class="chart-date-text"
                >
                  {{ item.date, }}
                </div>
              </div>
            </div>

            <div class="monthly-detail">
              <div class="title">{{ selectedMonthLabel }} Ayı Özeti</div>
              <BrandBasicTable
                style="margin-top: 30px;"
                :columns="[
                  { text: '', value: 'activity' },
                  {
                    text: `${activeTabIndex == 0 ? 'Kazandığım' : 'Kaybettiğim'} Puanlar`,
                    value: 'points',
                    icon: 'point',
                  },
                  {
                    text: `${activeTabIndex == 0 ? 'Kazandığım' : 'Kaybettiğim'} Miktar`,
                    value: 'amount',
                    icon: 'amount',
                  },
                ]"
                :items="monthItemsSummaries"
                :selectedMonthLabel="selectedMonthLabel"
                :activeTabIndex="activeTabIndex"
              />
            </div>
            <BrandButton
              v-if="monthItemDetails.length"
              class="detail-list-btn"
              :outlined="true"
              @click="isVisiblePreOrderHistory = !isVisiblePreOrderHistory"
            >
              <VueIcon
                class="point-detail"
                :iconName="icons.iconPointDetail.name"
                :width="icons.iconPointDetail.width"
                :height="icons.iconPointDetail.height"
              />
              <VueText style="color: #E5472D;" :isSingleLine="true" sizeLevel="6" weightLevel="4"
                >Detay Listesi</VueText
              ></BrandButton
            >
          </div>
        </VueTab>
      </VueTabs>
    </div>
    <ScoreTrackingDetailList
      v-else
      @backReturn="backReturn"
      :items="monthItemDetails"
      :selectedMonthLabel="selectedMonthLabel"
    />
  </div>
</template>
<script>
import { ref, computed, reactive } from 'vue';
import VueTabs from '@/components/shared/VueTabs/VueTabs.vue';
import VueTab from '@/components/shared/VueTab/VueTab.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon';
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandBarChart from '@/components/brand/BrandChart/BrandBarChart.vue';
import BrandBasicTable from '@/components/brand/BrandBasicTable/BrandBasicTable.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import ScoreTrackingDetailList from './ScoreTrackingDetailList.vue';
import { BusinessDashboard } from '@/services/Api/index';

export default {
  components: {
    VueTabs,
    VueTab,
    VueText,
    VueIcon,
    BrandButton,
    BrandBarChart,
    BrandBasicTable,
    ScoreTrackingDetailList,
  },
  setup() {
    const tabItems = ref(['Kazandığım Puanlar', 'Kaybettiğim Puanlar']);
    const isVisiblePreOrderHistory = ref(false);
    const selectedMonthYear = ref('');
    const selectedMonthLabel = ref('');
    const selectedItemIndex = ref(null);

    let monthSummaries = ref([]);
    let monthItemsSummaries = ref([]);
    let monthItemDetails = ref([]);
    let monthTableDetails = ref([]);

    let activeTabIndex = ref(0);
    let activeYear = ref(0);

    const setActiveTab = index => {
      activeTabIndex.value = index;
      getPointTracking();
    };

    const selectedBarItem = item => {
      selectedItemIndex.value = item.index;
      const selectedSummaries = [];

      const month = (item.index + 1)?.toString().padStart(2, '0');
      selectedMonthYear.value = `${activeYear.value.date}${month}`;

      monthSummaries.value?.forEach(f => {
        if (f.date == selectedMonthYear.value) {
          selectedSummaries.push(f.logs ?? []);
        }
      });

      const selectedDetails =
        monthTableDetails.value?.filter(f => f.date == selectedMonthYear.value)[0]?.logs ?? [];

      selectedMonthLabel.value = barChartData.labels[item.index];

      monthItemDetails.value = selectedDetails;

      monthItemsSummaries.value =
        selectedSummaries[0]?.map(item => ({
          activity: item.processTypeDesc || '',
          points: `${activeTabIndex.value === 0 ? '+' : ''} ${item.totalPoint} puan`,
          amount: `${activeTabIndex.value === 0 ? '+' : ''} ${item.totalAmount} TL`,
          info:
            item.processTypeDesc === 'Ödül Yağmuru'
              ? `${item.missedCount} Gün Kaçırdın! `
              : item.processTypeDesc === 'Yap Kazan'
              ? `${item.missedCount} Yap Kazan Kaçırdın`
              : '',
        })) ?? [];
    };

    const dateFilterItems = ref([
      { id: 0, date: new Date().getFullYear() - 1, isActive: false, isPrev: true },
      { id: 1, date: new Date().getFullYear(), isActive: true, isPrev: false },
    ]);

    const toggleDateFilter = item => {
      dateFilterItems.value?.forEach(filter => {
        filter.isActive = filter === item;
      });

      getPointTracking();
    };

    const barChartData = reactive({
      labels: [
        'Ocak',
        'Şubat',
        'Mart',
        'Nisan',
        'Mayıs',
        'Haziran',
        'Temmuz',
        'Ağustos',
        'Eylül',
        'Ekim',
        'Kasım',
        'Aralık',
      ],
      datasets: [
        {
          type: 'bar',
          label: '',
          data: [],
          backgroundColor: [],
          borderColor: [],
          borderRadius: 6,
          borderSkipped: false,
          barThickness: 40,
          datalabels: {
            display: false,
          },
          options: {
            scales: {
              yAxes: [
                {
                  ticks: {
                    fontColor: '#fff',
                  },
                },
              ],
              xAxes: [
                {
                  ticks: {
                    fontColor: '#fff',
                  },
                },
              ],
            },
            legend: {
              display: false,
            },
          },
        },
      ],
    });

    const barChartOptions = reactive({
      type: 'bar',
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            padding: 12,
          },
        },
        y: {
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            padding: 30,
            color: '#C2C2C2',
            font: {
              size: 12,
              weight: '400',
            },
          },
        },
      },
    });

    const params = ref({
      data: [
        ['', '', ''],
        [1, '', ''],
        [2, '', ''],
        [3, '', ''],
      ],
      header: 'row',
      headerHeight: 40,
      border: true,
      converting: false,
      rowHeight: 40,
      borderRadius: 5,
    });

    const icons = computed(() => {
      return ICON_VARIABLES;
    });

    const backReturn = () => {
      isVisiblePreOrderHistory.value = !isVisiblePreOrderHistory.value;
      setActiveTab(0);
    };

    const getPointTracking = async () => {
      const { data } = await BusinessDashboard.getPointTracking();

      if (!data.Data) return;

      const { earnedPointsLog, lostPointsLog, monthDetails } = data.Data;

      monthSummaries.value =
        activeTabIndex.value === 0 ? earnedPointsLog.monthSummaries : lostPointsLog.monthSummaries;

      monthTableDetails.value = monthDetails;

      const selectedYear = dateFilterItems.value.find(f => f.isActive);

      if (!selectedYear) return;

      const yearLogs =
        selectedYear.id === 0
          ? activeTabIndex.value === 0
            ? earnedPointsLog.prevYearLogs
            : lostPointsLog.prevYearLogs
          : activeTabIndex.value === 0
          ? earnedPointsLog.curYearLogs
          : lostPointsLog.curYearLogs;

      barChartData.datasets[0].data = getPointsLog(yearLogs);
      activeYear.value = selectedYear;

      const currentDate = new Date();
      let currentMonth = currentDate.getMonth();

      selectedBarItem({ index: currentMonth });
    };

    const getPointsLog = logs => {
      return setYearlyPoints(logs?.filter(item => item !== 0));
    };

    const getMonthNameFromMonthNumber = monthNumber => {
      const monthNames = [
        'Ocak',
        'Şubat',
        'Mart',
        'Nisan',
        'Mayıs',
        'Haziran',
        'Temmuz',
        'Ağustos',
        'Eylül',
        'Ekim',
        'Kasım',
        'Aralık',
      ];
      return monthNames[parseInt(monthNumber, 10) - 1];
    };

    const setYearlyPoints = data => {
      const points = Array(12).fill(0);
      data?.forEach(item => {
        const index = item.month - 1;
        points[index] = item.totalPoint;
      });
      barChartData.datasets[0].backgroundColor = points?.map(value =>
        value !== 0 ? '#F16F1B' : 'rgba(0, 0, 0, 0)',
      );
      return points;
    };

    return {
      getMonthNameFromMonthNumber,
      isVisiblePreOrderHistory,
      monthItemsSummaries,
      selectedItemIndex,
      selectedMonthYear,
      selectedMonthLabel,
      activeTabIndex,
      monthItemDetails,
      monthTableDetails,
      dateFilterItems,
      toggleDateFilter,
      barChartOptions,
      selectedBarItem,
      monthSummaries,
      getPointsLog,
      setActiveTab,
      barChartData,
      backReturn,
      activeYear,
      tabItems,
      params,
      icons,
    };
  },
};
</script>
<style lang="scss">
.score-tracking-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .score-tracking-content {
    display: flex;
    flex-direction: column;
  }

  .chart-divider {
    height: 1px;
    background-color: #ededed;
    width: 870px;
  }

  .detail-list-btn {
    border: 1px #e5472d solid;
    border-radius: 6px;
    width: 325px !important;
    height: 50px !important;
    margin-top: 65px;
    align-self: center;
  }

  .monthly-detail {
    margin: 40px 0 0 30px;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .point-detail {
    margin-right: palette-space-level(10);
    margin-top: palette-space-level(15);
  }
  .chart-date-text {
    font-size: 14px;
    margin-left: 5px;
    color: #c2c2c2;
    font-size: 14px;
    font-weight: 400;
  }

  .chart-date-filter {
    display: flex;
    align-items: flex-start;
    margin: 20px 0;
  }

  .chart-date-filter + div {
    display: flex;
    justify-content: flex-end;
  }

  .chart-date-item {
    display: flex;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;
  }
  .chart-date-text {
    font-size: 14px;
    font-weight: 500;
    margin-left: 5px;
  }
}
</style>
