<template>
  <div class="tracking-earnings">
    <div class="tracker-content">
      <div class="annual-earnings-summary">
        <span class="summary-title">Yıllık Toplam Kazanç</span>
        <span class="summary-summary">{{ formatNumberWithDot(yearlyTotalIncome) }} TL</span>
      </div>
      <div class="filters-container">
        <div class="filters-item" v-for="(item, i) in filterTypes" :key="i">
          <div class="filter-details">
            <div class="filter-point" :style="{ background: item.pointColor }" />
            <div class="filter-type">{{ item.text }}</div>
            <div class="filter-toggle">
              <label class="switch">
                <input type="checkbox" :checked="item.isActive" @change="toggleFilter(item)" />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
          <div class="filter-value">{{ formatNumberWithDot(item.value) }} TL</div>
        </div>
      </div>
    </div>
    <BrandBarChart
      @selectedBarItem="selectedBarItem"
      :chartData="barChartData"
      :chartOptions="barChartOptions"
      :hasBackgroundBarPlugin="true"
      :hasSelectedBar="true"
      style="margin-right: 20px;"
    />

    <div class="chart-divider" />
    <div class="chart-date-filter">
      <div
        class="chart-date-item"
        v-for="(item, index) in dateFilterItems"
        @click="toggleDateFilter(item)"
        :key="index"
      >
        <img
          :src="
            item.isActive
              ? require('@/assets/icons/green-check.svg')
              : require('@/assets/icons/empty-ellipse.svg')
          "
          alt="icon"
        />
        <div :style="{ color: item.isPrev ? '#ffc107' : '#F16F1B' }" class="chart-date-text">
          {{ item.date, }}
        </div>
      </div>
    </div>

    <div class="tracker-content">
      <div class="montly-earnings-summary">
        <span class="summary-title"> {{ selectedMonthLabel }} Ayı Toplam Kazanç</span>
        <span class="summary-summary">{{ formatNumberWithDot(monthlyTotalIncome) }} TL</span>
      </div>
      <div class="montly-earning-details">
        <div v-for="(month, index) in montlyDetails" :key="index" class="detail-item">
          <div class="detail-content">
            <div class="filter-point" :style="{ background: month.pointColor }" />
            <div class="detail-type">{{ month.text }}</div>
            <div class="detail-value">
              {{ getMonthDetailValue(month) }}
            </div>
          </div>
          <div class="divider" v-if="index < montlyDetails.length - 1" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, reactive, onBeforeMount, computed } from 'vue';
import BrandBarChart from '@/components/brand/BrandChart/BrandBarChart.vue';
import { BusinessDashboard } from '@/services/Api/index';

export default {
  components: { BrandBarChart },
  setup() {
    const selectedItemIndex = ref(null);
    const yearlyTotalIncome = ref('');
    const activeYear = ref(0);
    const fullMonthYear = ref('');
    const monthlyIncomes = ref({});
    const monthlyIncomeDetail = ref({});
    const selectedMonthLabel = ref();

    const filterTypes = ref([
      { text: 'Satış', isActive: true, value: '', pointColor: '#F16F1B', type: 'saleTotalIncome' },
      {
        text: 'Nakit Yatırım',
        isActive: true,
        value: '',
        pointColor: '#28B873',
        type: 'cashInvestmentTotalIncome',
      },
      {
        text: 'Aylık Puanlama',
        isActive: true,
        value: '',
        pointColor: '#A258B1',
        type: 'tradeTotalIncome',
      },
      {
        text: 'Yap-Kazan',
        isActive: true,
        value: '',
        pointColor: '#FFC107',
        type: 'ykTotalIncome',
      },
      {
        text: 'Yarışmalar',
        isActive: true,
        value: '',
        pointColor: '#4A51BB',
        type: 'marathonTotalIncome',
      },
      {
        text: 'E-Sipariş',
        isActive: true,
        value: '',
        pointColor: '#D02D2C',
        type: 'eorderTotalIncome',
      },
    ]);

    const montlyDetails = ref([
      { text: 'Satış', value: '', pointColor: '#F16F1B' },
      { text: 'Nakit Yatırım', value: '', pointColor: '#28B873' },
      { text: 'Aylık Puanlama', value: '', pointColor: '#A258B1' },
      { text: 'Yap-Kazan', value: '', pointColor: '#FFC107' },
      { text: 'Yarışmalar', value: '', pointColor: '#4A51BB' },
      { text: 'E-Sipariş', value: '', pointColor: '#D02D2C' },
    ]);

    const barChartData = reactive({
      labels: [
        'Ocak',
        'Şubat',
        'Mart',
        'Nisan',
        'Mayıs',
        'Haziran',
        'Temmuz',
        'Ağustos',
        'Eylül',
        'Ekim',
        'Kasım',
        'Aralık',
      ],
      datasets: [
        {
          label: 'Satış',
          backgroundColor: '#F16F1B',
          data: [],
          borderSkipped: false,
          barThickness: 40,
          hidden: false,
          display: false,
          datalabels: {
            display: false,
          },
        },
        {
          label: 'Nakit Yatırım',
          backgroundColor: '#28B873',
          hidden: false,
          data: [],
          barThickness: 40,
          borderSkipped: false,
          datalabels: {
            display: false,
          },
        },
        {
          label: 'Aylık Puanlama',
          backgroundColor: '#A258B1',
          hidden: false,
          data: [],
          barThickness: 40,
          borderSkipped: false,
          datalabels: {
            display: false,
          },
        },
        {
          label: 'Yap-Kazan',
          backgroundColor: '#FFC107',
          hidden: false,
          data: [],
          barThickness: 40,
          borderSkipped: false,
          datalabels: {
            display: false,
          },
        },
        {
          label: 'Yarışmalar',
          backgroundColor: '#4A51BB',
          hidden: false,
          data: [],
          barThickness: 40,
          borderSkipped: false,
          datalabels: {
            display: false,
          },
        },
        {
          label: 'E-Sipariş',
          backgroundColor: '#D02D2C',
          hidden: false,
          data: [],
          barThickness: 40,
          borderSkipped: false,
          datalabels: {
            display: false,
          },
        },
      ],
    });

    const barChartOptions = ref({
      type: 'bar',
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            padding: 12,
            color: context => {
              if (context.index === +selectedItemIndex.value) {
                return '#F16F1B';
              } else {
                return '#C2C5C3';
              }
            },
            font: context => {
              const baseFont = {
                size: 12,
                weight: '400',
              };

              if (context.index === +selectedItemIndex.value) {
                return {
                  ...baseFont,
                  size: 14,
                  weight: '500',
                };
              } else {
                return baseFont;
              }
            },
          },
        },
        y: {
          stacked: true,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            padding: 30,
            color: '#C2C2C2',
            font: {
              size: 12,
              weight: '400',
            },
          },
        },
      },
    });

    const dateFilterItems = ref([
      { id: 0, date: new Date().getFullYear() - 1, isActive: false, isPrev: true },
      { id: 1, date: new Date().getFullYear(), isActive: true, isPrev: false },
    ]);

    const toggleDateFilter = item => {
      dateFilterItems.value?.forEach(filter => {
        filter.isActive = false;
      });

      item.isActive = true;

      activeYear.value = item.date;

      setDetailYear();

      getIncomeTracking();
    };

    const selectedBarItem = item => {
      if (!item) return;

      selectedItemIndex.value = item.index;
      selectedMonthLabel.value = barChartData.labels[item.index];

      setDetailYear();
      getIncomeTracking();
    };

    const fillMissingMonths = data => {
      const currentYear = new Date().getFullYear();
      const latestDate = data.reduce((max, { date }) => (date > max ? date : max), '000000');
      const [latestYear, latestMonth] = [
        parseInt(latestDate.slice(0, 4), 10),
        parseInt(latestDate.slice(4, 6), 10),
      ];

      for (let year = latestYear; year <= currentYear; year++) {
        for (let month = year === latestYear ? latestMonth + 1 : 1; month <= 12; month++) {
          const monthString = `${year}${month.toString().padStart(2, '0')}`;
          if (!data.some(({ date }) => date === monthString)) {
            data.push({
              date: monthString,
              monthlyTotalIncome: 0,
              incomes: [
                'Nakit Yatırım',
                'Aylık Puanlama',
                'Yap-Kazan',
                'Yarışmalar',
                'E-Sipariş',
                'Satış',
              ].map(type => ({ type, totalIncome: 0 })),
            });
          }
        }
      }
    };

    const getIncomeTracking = async () => {
      const { data } = await BusinessDashboard.getIncomeTracking();

      if (!data.Data) return;

      monthlyIncomes.value = data.Data.monthlyIncomes;

      fillMissingMonths(monthlyIncomes.value);

      monthlyIncomeDetail.value =
        monthlyIncomes?.value?.filter(f => f.date == fullMonthYear.value)[0] ?? {};

      const isCurYear = activeYear.value == new Date().getFullYear();

      const currentFilterData = isCurYear ? data.Data.curYear : data.Data.prevYear;

      yearlyTotalIncome.value = currentFilterData.yearlyTotalIncome;

      filterTypes.value?.forEach(filter => {
        if (currentFilterData.hasOwnProperty(filter.type)) {
          filter.value =
            currentFilterData[filter.type] === 0 ? '0' : currentFilterData[filter.type];
        }
      });

      montlyDetails.value?.forEach(item1 => {
        let matchingItem = monthlyIncomeDetail.value.incomes?.find(
          item2 => item2.type === item1.text,
        );
        if (matchingItem) {
          item1.value = matchingItem.totalIncome;
        }
      });

      const filteredData = monthlyIncomes.value?.filter(month =>
        month.date.startsWith(activeYear.value),
      );

      barChartData.datasets?.forEach(dataset => {
        dataset.data = [];
      });

      filteredData?.forEach(month => {
        barChartData.datasets?.forEach(dataset => {
          const income = month.incomes?.find(income => income.type === dataset.label);
          dataset.data.push(income ? income.totalIncome : 0);
        });
      });
    };

    const setDetailYear = () => {
      const selectedYear = dateFilterItems.value?.find(f => f.isActive).date;
      if (!selectedYear) return;

      activeYear.value = selectedYear;

      const selectedMonth = +selectedItemIndex.value + 1;

      fullMonthYear.value = `${activeYear.value}${
        selectedMonth < 10 ? '0' + selectedMonth : selectedMonth
      }`;
    };

    const updateBarChartData = () => {
      barChartData?.datasets?.forEach(dataset => {
        const filter = filterTypes.value?.find(f => f.text === dataset.label);
        dataset.hidden = !filter.isActive;
      });
    };

    const toggleFilter = item => {
      item.isActive = !item.isActive;

      updateBarChartData();
    };

    const formatNumberWithDot = number => {
      return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    };

    const monthlyTotalIncome = computed(() => {
      return monthlyIncomeDetail.value.monthlyTotalIncome ?? '0';
    });

    const getMonthDetailValue = month => {
      return formatNumberWithDot(month.value) + (month.text !== 'Aylık Puanlama' ? ' TL' : ' Puan');
    };

    onBeforeMount(() => {
      const currentDate = new Date();
      let currentMonth = currentDate.getMonth();
      selectedBarItem({ index: currentMonth });
    });

    return {
      activeYear,
      filterTypes,
      toggleFilter,
      barChartData,
      montlyDetails,
      setDetailYear,
      barChartOptions,
      toggleDateFilter,
      dateFilterItems,
      selectedBarItem,
      getIncomeTracking,
      yearlyTotalIncome,
      monthlyTotalIncome,
      updateBarChartData,
      formatNumberWithDot,
      selectedMonthLabel,
      getMonthDetailValue,
      selectedItemIndex,
    };
  },
};
</script>
<style scoped lang="scss">
.tracking-earnings {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;

  .tracker-content {
    width: 830px;
    display: flex;
    flex-direction: column;

    .annual-earnings-summary,
    .montly-earnings-summary {
      height: 105px;
      display: flex;
      border-radius: 8px;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: #f8f9f9;
      border: 1px solid #ededed;

      .summary-title {
        color: #79838e;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 10px;
      }

      .summary-summary {
        font-size: 32px;
        font-weight: 800;
      }
    }

    .filters-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 20px;
    }

    .filters-item {
      height: auto;
      width: 266px;
      border-radius: 8px;
      border: 1px solid #ededed;
      background: #f8f9f9;
      padding: 20px;
      display: flex;
      margin-bottom: 20px;
      justify-content: space-between;
      flex-direction: column;
    }

    .filter-details {
      display: flex;
      align-items: center;

      .filter-type {
        color: #79838e;
        font-size: 14px;
        font-weight: 400;
        padding-left: 8px;
      }

      .filter-point {
        width: 8px;
        height: 8px;
        border-radius: 100%;
      }
    }
    .filter-value {
      font-size: 18px;
      font-weight: 800;
      padding-top: 10px;
    }

    .filter-toggle {
      margin-left: auto;

      .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
      }

      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      input:checked + .slider {
        background: linear-gradient(rgba(210, 0, 81, 1), rgba(255, 163, 0, 1));
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      .slider:before {
        position: absolute;
        content: '';
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      .slider.round {
        border-radius: 34px;
      }

      .slider.round:before {
        border-radius: 50%;
      }
    }

    .montly-earning-details {
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      border: 1px solid #ededed;
      background: #f8f9f9;
      padding: 30px;
      margin-top: 20px;

      .detail-item {
        display: flex;
        flex-direction: column;

        .detail-content {
          display: flex;
          align-items: center;
          padding: 10px 0;

          .filter-point {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-right: 10px;
          }

          .detail-type {
            color: #79838e;
            font-size: 14px;
            font-weight: 400;
            margin-right: auto;
          }

          .detail-value {
            font-size: 18px;
            font-weight: 800;
          }
        }

        .divider {
          height: 1px;
          background-color: #ededed;
          width: 770px;
          margin: 10px 0;
        }
      }
    }
  }

  .chart-divider {
    height: 1px;
    background-color: #ededed;
    width: 870px;
  }

  .chart-date-filter {
    display: flex;
    align-items: flex-start;
    margin: 20px 0;
  }

  .chart-date-filter + div {
    display: flex;
    justify-content: flex-end;
  }

  .chart-date-item {
    display: flex;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;
  }
  .chart-date-text {
    font-size: 14px;
    font-weight: 500;
    margin-left: 5px;
  }
}
</style>
