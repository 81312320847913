<template>
  <div class="days-table">
    <div class="day" v-for="(day, index) in days" :key="index">
      <div class="day-name">{{ day }}</div>
      <div class="day-check" v-for="(partner, i) in partners" :key="i">
        <img
          class="partner-logo"
          v-if="index === 0"
          :src="partner.partnerImage"
          :alt="partner.partnerName"
        />
        <img v-if="isChecked(day, partner)" src="~@/assets/icons/icon_check.svg" alt="check" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { User } from '@/services/Api/index';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import { ICON_VARIABLES } from '@/constants/component.constants.js';

export default {
  props: {
    activeTabIndex: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const days = ref(['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar']);
    const partners = ref([]);

    const getRouteDays = () => {
      User.getRouteDays().then(res => {
        if (!res?.data?.Data) return;
        partners.value = res.data.Data ?? [];
      });
    };

    const constants = () => {
      return {
        flexAlignment: FLEX_JUSTIFIES,
        icons: ICON_VARIABLES,
      };
    };

    const isChecked = (day, partner) => {
      const routeDays = props.activeTabIndex === 0 ? partner?.routeDays : partner?.routeOrderDays;
      return routeDays?.includes(day) || false;
    };

    onMounted(() => {
      getRouteDays();
    });

    return {
      constants,
      partners,
      isChecked,
      days,
    };
  },
};
</script>

<style scoped lang="scss">
.partner-logo {
  position: absolute;
  left: -80%;
  top: 50%;
  transform: translateY(-50%);
  padding-right: 10px;
  width: 80px;
  height: 40px;
}
.days-table {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid #ededed;
  background: #f8f9f9;
  width: 835px;
}

.day {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  &-name {
    background-color: palette-color-level('grey', 10);
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid transparentize(#ededed, 0.5);
    border-right: 1px solid transparentize(#ededed, 0.5);
    color: #23303d;
    font-size: 18px;
    font-weight: 400;
  }

  &-check {
    height: 118px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    border-bottom: 1px solid transparentize(#ededed, 0.5);
    border-right: 1px solid transparentize(#ededed, 0.5);
    border-left: 1px solid transparentize(#ededed, 0.5);
  }
}
</style>
