<template>
  <div>
    <div style="margin-left:105px" v-if="!isVisiblePreOrderHistory">
      <VueTabs @selectedTab="setActiveTab">
        <VueTab
          v-for="(item, index) in tabItems"
          :selected="activeTabIndex === index"
          :key="index"
          :name="item"
          :id="index"
        >
          <div class="track-pre-order-content">
            <DaysTable style="margin-left:130px" :activeTabIndex="activeTabIndex" />
            <BrandButton
              class="pre-history-btn"
              :outlined="true"
              @click="isVisiblePreOrderHistory = !isVisiblePreOrderHistory"
            >
              <VueIcon
                class="point-detail"
                :iconName="icons.iconPointDetail.name"
                :width="icons.iconPointDetail.width"
                :height="icons.iconPointDetail.height"
              />
              <VueText style="color: #E5472D;" :isSingleLine="true" sizeLevel="6" weightLevel="4"
                >Ön Sipariş Geçmişi</VueText
              ></BrandButton
            >
          </div>
        </VueTab>
      </VueTabs>
    </div>
    <PreOrderHistoryTable v-else @showDaysTable="showDaysTable" />
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import VueTabs from '@/components/shared/VueTabs/VueTabs.vue';
import VueTab from '@/components/shared/VueTab/VueTab.vue';
import DaysTable from '@/components/brand/DaysTable/DaysTable.vue';
import PreOrderHistoryTable from './PreOrderHistoryTable.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import VueIcon from '@/components/shared/VueIcon/VueIcon';

export default {
  components: {
    VueTabs,
    VueTab,
    DaysTable,
    BrandButton,
    VueText,
    VueIcon,
    PreOrderHistoryTable,
  },
  setup() {
    const activeTabIndex = ref(0);
    const isVisiblePreOrderHistory = ref(false);

    const tabItems = ref(['Ziyaret Günlerim', 'Ön Sipariş Günlerim']);

    const setActiveTab = index => {
      activeTabIndex.value = index;
    };

    const icons = computed(() => {
      return ICON_VARIABLES;
    });

    const showDaysTable = () => {
      isVisiblePreOrderHistory.value = !isVisiblePreOrderHistory.value;
    };

    return {
      isVisiblePreOrderHistory,
      activeTabIndex,
      setActiveTab,
      showDaysTable,
      tabItems,
      icons,
    };
  },
};
</script>
<style scoped lang="scss">
.point-detail {
  margin-right: palette-space-level(10);
  margin-top: palette-space-level(15);
}

.track-pre-order-content {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.pre-history-btn {
  margin-top: 70px;
  align-self: center;
  border-radius: 6px;
  width: 325px !important;
  height: 50px !important;
  border: 1px #e5472d solid;
}
</style>
