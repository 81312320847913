<template>
  <Pie
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { defineComponent } from 'vue';
import { Pie } from 'vue-chartjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, ChartDataLabels);

export default defineComponent({
  name: 'BrandPieChart',
  components: {
    Pie,
  },
  props: {
    chartId: {
      type: String,
      default: 'pie-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 150,
    },
    height: {
      type: Number,
      default: 150,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => ({
        height: '140px',
        width: '140px',
      }),
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    chartData: {
      type: Object,
    },
    chartOptions: {
      type: Object,
    },
  },
  setup(props) {
    return {
      chartOptions: props.chartOptions,
      datasetIdKey: props.datasetIdKey,
      cssClasses: props.cssClasses,
      chartData: props.chartData,
      chartId: props.chartId,
      plugins: props.plugins,
      styles: props.styles,
      height: props.height,
      width: props.width,
    };
  },
});
</script>

<style></style>
