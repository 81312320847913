<template>
  <div class="pre-order-history-container">
    <VueText style="font-size: 30px; color: #23303D;  margin-bottom: 30px;" weightLevel="3"
      >Ön Sipariş Geçmişi</VueText
    >
    <VueText style="color: #79838E; margin-bottom: 20px;" sizeLevel="6" weightLevel="2"
      >Ön sipariş numarasına göre ara</VueText
    >
    <VueTableDynamic :params="params" ref="tableRef" id="table" />
  </div>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import VueTableDynamic from 'vue-table-dynamic';
import { ref, onBeforeMount, nextTick } from 'vue';
import { BusinessDashboard } from '@/services/Api/index';

const emits = ['showDaysTable'];

export default {
  components: { VueTableDynamic, VueText },
  emits,
  setup(props, { emit }) {
    const tableRef = ref(null);
    const isTextAppended = ref(false);

    const params = ref({
      data: [
        ['Ön Sipariş Tarihi', 'Teslimat Günü', 'Ön Sipariş No', 'Ödeme Tipi', 'Toplam', 'Durum'],
      ],
      header: 'row',
      headerHeight: 80,
      rowHeight: 65,
      border: true,
      enableSearch: true,
      converting: false,
      headerBgColor: '#f8f9f9',
    });

    const desiredOrder = [
      'preOrderOrderDate',
      'preOrderDeliveryDate',
      'preOrderCode',
      'prePaymentFormDescription',
      'preOrderNetAmountDocCur',
      'preOrderStatus',
    ];

    const appendTextToTable = async () => {
      if (isTextAppended.value) return;

      await nextTick();

      const toolsSearchDiv = document?.querySelector('.tools-search');
      const inputElement = toolsSearchDiv?.querySelector('input');

      if (inputElement) {
        inputElement.placeholder = 'Ön Sipariş Numarası';
      }

      const table = document?.querySelector('.v-table-tools');
      const existingTextElement = document?.getElementById('backButton');
      if (existingTextElement) {
        isTextAppended.value = true;
        return;
      }

      const newTextElement = createTextElement();

      if (newTextElement && table) {
        newTextElement.addEventListener('click', () => {
          handleTextElementClick();
        });

        table.appendChild(newTextElement);
        isTextAppended.value = true;
      }
    };

    const createTextElement = () => {
      const newTextElement = document?.createElement('div');

      if (newTextElement) {
        newTextElement.textContent = 'Geri Dön';
        newTextElement.style.cssText = `
          color: #E5472D;
          font-family: Roboto;
          font-size: 18px;
          font-weight: 700;
          margin-left: 550px;
          cursor: pointer;
        `;
        newTextElement.id = 'backButton';
      }

      return newTextElement;
    };

    const handleTextElementClick = () => {
      emit('showDaysTable');
    };

    const formatDateString = date => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date
        .getDate()
        .toString()
        .padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const queryString = () => {
      const currentDate = new Date();

      const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

      const startDateFormatted = formatDateString(firstDayOfMonth);
      const endDateFormatted = formatDateString(lastDayOfMonth);
      return `startDate=${startDateFormatted}&endDate=${endDateFormatted}`;
    };

    const formatOrderDate = date => {
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear() % 100}`;
    };

    const getDeliveryDay = date => {
      return date.toLocaleDateString('tr-TR', { weekday: 'long' });
    };

    const getStatusText = status => {
      switch (status) {
        case 'X':
          return 'İptal';
        case 'O':
          return 'Açık';
        case 'F':
          return 'Sonlandırılmış';
        default:
          return 'Bilinmeyen Durum';
      }
    };

    const transformDataItem = item => {
      const orderDate = new Date(item.preOrderOrderDate);
      const formattedOrderDate = formatOrderDate(orderDate);

      const deliveryDate = new Date(item.preOrderDeliveryDate);
      const deliveryDay = getDeliveryDay(deliveryDate);

      const statusText = getStatusText(item.preOrderStatus);

      const transformedItem = {};
      desiredOrder?.forEach(key => {
        transformedItem[key] = item[key];
      });

      return {
        ...transformedItem,
        preOrderOrderDate: formattedOrderDate,
        preOrderDeliveryDate: deliveryDay,
        preOrderStatus: statusText,
      };
    };

    const preOrderHistory = () => {
      const query = queryString();

      BusinessDashboard.getPreOrderHistory(query).then(res => {
        if (!res.data.Data) return;

        const transformedData = res.data?.Data?.map(item => {
          return Object.values(transformDataItem(item));
        });

        params.value.data.push(...transformedData);
      });
    };

    onBeforeMount(() => {
      isTextAppended.value = false;
      appendTextToTable();
      preOrderHistory();
    });

    return {
      params,
      tableRef,
      queryString,
      preOrderHistory,
      formatOrderDate,
      getDeliveryDay,
      appendTextToTable,
      transformDataItem,
      getStatusText,
      formatDateString,
      createTextElement,
      handleTextElementClick,
    };
  },
};
</script>
<style lang="scss">
.pre-order-history-container {
  margin: 0 15%;
  .tools-search {
    width: 270px !important;
    margin-bottom: 20px;
  }
  .v-table-row {
    color: #23303d;
  }
  .v-table-row.is-header {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400 !important;
    color: #79838e;
    font-family: Roboto;
    text-align: center;
  }
  .table-cell-inner {
    place-content: center;
  }
  .table-cell {
    text-align-last: center;
    font-family: Roboto;
    font-size: 18px;
  }

  .vue-input.prefix {
    height: 50px;
    color: #79838e;
    font-size: 16px;
    font-weight: 400 !important;
    font-family: Roboto !important;
  }
}
</style>
