<template>
  <div class="breadcrumb-container">
    <ul>
      <li v-for="(breadcrumb, index) in crumbs" :key="index">
        <a :href="breadcrumb.to" class="breadcrumb-link">{{ breadcrumb.text }}</a>
        <span v-if="index < crumbs.length - 1"> </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    breadcrumbList: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const crumbs = computed(() => {
      if (!props.breadcrumbList || !Array.isArray(props.breadcrumbList)) return [];

      return props.breadcrumbList?.map(item => {
        return {
          path: item.link,
          to: item.link,
          text: item.name,
        };
      });
    });

    return {
      crumbs,
    };
  },
};
</script>

<style scoped lang="scss">
.breadcrumb-link {
  color: #79838e;
  text-decoration-line: none;
}
</style>
