<template>
  <div class="score-tracking-detail-container">
    <div class="detail-header">
      <div class="monthly-detail-title">{{ selectedMonthLabel }} Ayı Özeti</div>
      <div class="backButton" @click="backReturn">Geri Dön</div>
    </div>
    <VueTableDynamic :params="params" ref="tableRef" id="table">
      <template v-slot:column-2="{ props }">
        <div :style="{ color: props.cellData < 0 ? '#E02020' : '#6DD400', fontWeight: 700 }">
          {{ props.cellData > 0 ? '+' : '' }} {{ props.cellData }} puan
        </div>
      </template>
    </VueTableDynamic>
  </div>
</template>

<script>
import VueTableDynamic from 'vue-table-dynamic';
import { onMounted, ref } from 'vue';

export default {
  components: { VueTableDynamic },
  props: {
    items: {
      type: Array,
      required: true,
    },
    selectedMonthLabel: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const params = ref({
      data: [['Partner', 'Tarih', 'Puan', 'Tutar']],
      header: 'row',
      headerHeight: 55,
      rowHeight: 60,
      border: true,
      enableSearch: false,
      converting: false,
      headerBgColor: '#f8f9f9',
      pagination: true,
      pageSize: 15,
    });

    const formatDate = dateString => {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    };

    const backReturn = () => {
      emit('backReturn');
    };

    onMounted(() => {
      props.items?.forEach(log => {
        const formattedDate = formatDate(log.date);

        const row = [
          log.processTypeDesc,
          formattedDate,
          `${log.totalPoint}`,
          `${log.totalAmount > 0 ? '+' : ''}${log.totalAmount} TL`,
        ];
        params.value.data.push(row);
      });
    });

    return {
      params,
      backReturn,
    };
  },
};
</script>
<style lang="scss">
.score-tracking-detail-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  margin: 0 5%;

  .detail-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
  }

  .v-table-row {
    color: #23303d;
  }
  .v-table-row.is-header {
    font-size: 14px;
    font-weight: 400 !important;
    color: #79838e;
    text-align: center;
  }
  .table-cell-inner {
    place-content: center;
  }
  .table-cell {
    text-align-last: center;
    font-size: 16px;
    display: flex;
    justify-content: center;
  }
  .vue-input.prefix {
    height: 50px;
    color: #79838e;
    font-size: 16px;
    font-weight: 400 !important;
  }
  .backButton {
    cursor: pointer;
    color: #e5472d;
    font-size: 18px;
    font-weight: 700;
  }

  .monthly-detail-title {
    font-size: 20px;
    font-weight: 700;
    margin-right: auto;
  }
}
</style>
