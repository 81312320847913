<template>
  <Bar
    :dataset-id-key="datasetIdKey"
    :chart-options="computedChartOptions"
    :css-classes="cssClasses"
    :chart-data="chartData"
    :chart-id="chartId"
    :plugins="plugins"
    :styles="styles"
    ref="chartRef"
    :height="height"
    :width="width"
    id="chartId"
  />
</template>

<script>
import { Bar } from 'vue-chartjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, ArcElement, CategoryScale, registerables } from 'chart.js';
import { ref, watch, defineComponent, computed } from 'vue';

ChartJS.register(...registerables, ArcElement, CategoryScale, ChartDataLabels);

export default defineComponent({
  name: 'BrandBarChart',
  components: {
    Bar,
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 150,
    },
    height: {
      type: Number,
      default: 150,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => ({
        height: 'auto',
        width: '870px',
      }),
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      required: true,
    },
    hasBackgroundBarPlugin: {
      type: Boolean,
      default: false,
    },
    activeTabIndex: {
      type: Number,
    },
    hasSelectedBar: {
      type: Boolean,
      default: false,
    },
    activeYear: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const chartRef = ref(null);
    const selectedBarIndex = ref(new Date().getMonth());
    const backgroundFillStyle = ref('#EDEDED');

    watch(
      () => props.activeTabIndex,
      () => {
        selectedBarIndex.value = new Date().getMonth();

        const chart = chartRef.value.chart;

        if (!chart) return;
        chart.config._config.options.scales.y.reverse = !!props.activeTabIndex;
        chart.update();
      },
    );

    watch(
      [() => props.activeYear, () => selectedBarIndex.value],
      ([activeYear], [prevActiveYear]) => {
        if (activeYear !== prevActiveYear) {
          selectedBarIndex.value = new Date().getMonth();
        }

        const chart = chartRef.value.chart;

        if (!chart) return;
        chart.update();
      },
    );

    const computedChartOptions = computed(() => {
      return {
        ...props.chartOptions,
        scales: {
          ...props.chartOptions.scales,
          x: {
            ...props.chartOptions.scales.x,
            ticks: {
              ...props.chartOptions.scales.x.ticks,
              color: context => {
                if (context.index === selectedBarIndex.value && props.hasSelectedBar) {
                  return '#F16F1B';
                } else {
                  return '#C2C5C3';
                }
              },
              font: context => {
                const baseFont = {
                  size: 12,
                  weight: '400',
                };

                if (context.index === selectedBarIndex.value && props.hasSelectedBar) {
                  return {
                    ...baseFont,
                    size: 14,
                    weight: '500',
                  };
                } else {
                  return baseFont;
                }
              },
            },
          },
        },
        onClick,
      };
    });

    const backgroundBarPlugin = {
      id: 'backgroundBar',
      beforeDatasetsDraw(chart) {
        const {
          data,
          ctx,
          chartArea: { top, height },
          scales: { x },
        } = chart;

        ctx.fillStyle = backgroundFillStyle.value;

        ctx.save();

        data.datasets.forEach(dataset => {
          const barWidth = dataset.barThickness !== undefined ? dataset.barThickness : 20;

          dataset.data.forEach((value, dataIndex) => {
            const barX = x.getPixelForValue(dataIndex) - barWidth / 2;
            const margin = 5;
            const bottomMargin = -5;
            const borderRadius = 8;
            const marginTop = -5;

            if (props.hasSelectedBar && selectedBarIndex.value === dataIndex) {
              ctx.fillStyle = 'white';
              ctx.strokeStyle = '#F16F1B';
              ctx.lineWidth = 1;
            } else {
              ctx.fillStyle = '#EDEDED';
              ctx.strokeStyle = 'transparent';
              ctx.lineWidth = 0;
            }

            ctx.beginPath();
            ctx.moveTo(barX - margin + borderRadius, top + marginTop);
            ctx.lineTo(barX + barWidth + margin - borderRadius, top + marginTop);
            ctx.quadraticCurveTo(
              barX + barWidth + margin,
              top + marginTop,
              barX + barWidth + margin,
              top + borderRadius + marginTop,
            );
            ctx.lineTo(barX + barWidth + margin, top + height - borderRadius - bottomMargin);
            ctx.quadraticCurveTo(
              barX + barWidth + margin,
              top + height - bottomMargin,
              barX + barWidth + margin - borderRadius,
              top + height - bottomMargin,
            );
            ctx.lineTo(barX - margin + borderRadius, top + height - bottomMargin);
            ctx.quadraticCurveTo(
              barX - margin,
              top + height - bottomMargin,
              barX - margin,
              top + height - borderRadius - bottomMargin,
            );
            ctx.lineTo(barX - margin, top + borderRadius + marginTop);
            ctx.quadraticCurveTo(
              barX - margin,
              top + marginTop,
              barX - margin + borderRadius,
              top + marginTop,
            );
            ctx.closePath();
            ctx.fill();
            ctx.stroke();
          });
        });

        ctx.restore();
      },
    };

    const onClick = event => {
      const chart = chartRef.value.chart;
      if (!chart) return;
      const elementsAtEvent = chart.getElementsAtEventForMode(event, 'index', { intersect: false });
      if (elementsAtEvent.length) {
        selectedBarIndex.value = elementsAtEvent[0].index;
        emit('selectedBarItem', { index: selectedBarIndex.value });
      } else {
        selectedBarIndex.value = -1;
        emit('selectedBarItem', null);
      }
    };
    return {
      plugins: [...props.plugins, props.hasBackgroundBarPlugin ? backgroundBarPlugin : []],
      computedChartOptions,
      datasetIdKey: props.datasetIdKey,
      cssClasses: props.cssClasses,
      chartData: props.chartData,
      chartId: props.chartId,
      styles: props.styles,
      height: props.height,
      width: props.width,
      backgroundFillStyle,
      selectedBarIndex,
      chartRef,
    };
  },
});
</script>
