<template>
  <div class="follow-products-container" ref="productsContainer">
    <div class="follow-products-content">
      <div class="header-container">
        <BrandMultiSelect
          :options="productFamilyCodes"
          @selectedOptions="selectedFamilyCode"
          :placeholder="`Ürün Ailesi Seç`"
          :modelValue="selectedCode"
        />
        <span class="products-header">Ürünler</span>
      </div>
      <div class="products">
        <div class="products-container" v-for="(item, index) in filteredProducts" :key="index">
          <div class="product-item" @click.prevent="onClickProduct(item)">
            <img :src="item.img" width="60" height="100" />
          </div>
          <span>{{ item.name }}</span>
        </div>
      </div>

      <div class="more-products" @click="toggleShowMore">
        {{ showMore ? 'Daha Az Göster' : 'Daha Fazla Gör' }}
      </div>

      <div class="progress-container" :style="containerStyle">
        <ProductProgressCircle
          v-if="percentage != 0"
          :percentage="percentage"
          :circumference="circumference"
        />
        <ProgressEmpty v-else />
      </div>

      <ProductDetails :toP3SkuList="toP3SkuList" :strategicSkuList="strategicSkuList" />
    </div>
  </div>
</template>

<script>
import { onBeforeMount, ref, computed } from 'vue';
import ProgressEmpty from './ProgressEmpty.vue';
import ProductDetails from './ProductDetails.vue';
import { BusinessDashboard } from '@/services/Api/index';
import ProductProgressCircle from './ProductProgressCircle.vue';
import BrandMultiSelect from '@/components/brand/BrandMultiSelect/BrandMultiSelect';

export default {
  components: { BrandMultiSelect, ProductProgressCircle, ProgressEmpty, ProductDetails },
  setup() {
    const strategicSkuList = ref([]);
    const toP3SkuList = ref([]);
    const products = ref([]);
    const showMore = ref(false);
    const productFamilyCodes = ref([]);
    const productsContainer = ref(null);
    const selectedCode = ref([]);
    const percentage = ref(0);
    const radius = 70;

    const circumference = computed(() => 2 * Math.PI * radius);

    const filteredProducts = computed(() => {
      const filtered = products.value.filter(
        product =>
          selectedCode.value.length === 0 || selectedCode.value.value == product.productFamilyCode,
      );

      return showMore.value ? filtered : filtered.slice(0, 6);
    });

    const containerStyle = computed(() => ({
      backgroundColor: percentage.value === 0 ? 'rgba(18, 134, 251, 0.15)' : '#f8f9f9',
      border: percentage.value === 0 ? '0.5px solid rgba(18, 134, 251, 0.40)' : '1px solid #ececec',
    }));

    const toggleShowMore = () => {
      showMore.value = !showMore.value;
      getProductTracking();
      productsContainer.value.scrollIntoView({ behavior: 'smooth' });
    };

    const getProductTracking = () => {
      BusinessDashboard.getProductTracking().then(res => {
        const {
          productFamilyList,
          strategicSkuBSandYTDVarList,
          toP3SkuBSandYTDVarList,
          productHandlingList,
          top3OOS,
        } = res.data.Data.productTrackingData;

        percentage.value = top3OOS;
        toP3SkuList.value = toP3SkuBSandYTDVarList;
        strategicSkuList.value = strategicSkuBSandYTDVarList;

        products.value = (showMore.value ? productHandlingList : productFamilyList).map(item => ({
          name: item.productName || item.productFamilyDescription,
          img: item.image,
          productFamilyCode: item.productFamilyCode,
        }));
        const uniqueFamilyCodes = [
          ...new Set(productHandlingList.map(item => item.productFamilyCode)),
        ];

        productFamilyCodes.value = uniqueFamilyCodes.map(code => ({ value: code, label: code }));
      });
    };

    const selectedFamilyCode = codes => {
      if (!codes || codes.length === 0) {
        selectedCode.value = [];
      } else {
        selectedCode.value = codes;
      }
    };

    const onClickProduct = item => {
      if (!showMore.value) console.log(item);
      selectedFamilyCode({
        value: item.productFamilyCode ?? '',
        label: item.productFamilyCode ?? '',
      });
    };
    onBeforeMount(() => {
      getProductTracking();
    });

    return {
      products,
      showMore,
      percentage,
      toP3SkuList,
      selectedCode,
      circumference,
      toggleShowMore,
      containerStyle,
      strategicSkuList,
      productFamilyCodes,
      filteredProducts,
      productsContainer,
      selectedFamilyCode,
      onClickProduct,
    };
  },
};
</script>

<style scoped lang="scss">
.follow-products-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10%;
}

.header-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
}

.products-header {
  font-size: 18px;
  margin-top: 20px;
  font-weight: 800;
}

.follow-products-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.products {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  width: 100%;
}

.products-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px 15px 10px;
}

.products-container span {
  font-size: 14px;
  text-align: center;
}

.product-item {
  display: flex;
  width: 150px;
  height: 150px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #b4c2d3;
  margin-bottom: 10px;
}

.more-products {
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  border: 1px solid #b4c2d3;
  cursor: pointer;
  text-align: center;
  margin: 20px 0 80px 0;
  width: 200px;
  height: 50px;
}

.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 20px;
  width: 830px;
  height: 300px;
}
</style>
